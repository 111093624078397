import { compareArray, getItemsInOriginalOrder } from "@/utils"

export default {
  name : "CascadedInput",
  props: {
    pItems               : Array,
    pSelected            : Array,
    pMultiple            : Boolean,
    pLoading             : Boolean,
    pLabel               : String,
    pClearable           : Boolean,
    pEmitOnEverySelection: Boolean,
    pClearAsListItem     : Boolean,
    pDense               : Boolean,
    pColor               : String,
    pBackgroundColor     : String,
    pItemKey             : String,
    pItemText            : String,
    pErrorMessages       : Array,
    pHideDetails         : Boolean,
    pClearIcon           : String,
    pPlaceholder         : String,
    pAppendOuterIcon     : String,
    pReadonly            : Boolean
  },
  data: () => ({
    isMenuOpen: false,
    selected  : [],
    search    : "",
    clearing  : false
  }),
  computed: {
    selectedValue() {
      if (this.pSelected) {
        return this.pSelected.map(selectedItem => {
          const item = this.getItemByKey(this.pItems, selectedItem)
          if (item) {
            return item[this.pItemText]
          }
        })
      }
      return []
    }
  },
  methods: {
    handleClear() {
      this.clearing = true
      this.selected = []
      this.$emit("selected", [])
    },
    getParentItemClass(item) {
      return item.children?.length ? "font-weight-bold black--text" : "font-weight-regular"
    },
    onMenuOpen(isMenuOpen) {
      if (isMenuOpen) {
        this.$emit("focus")
      } else {
        if (!this.pEmitOnEverySelection) {
          if (!compareArray(this.selected, this.pSelected)) {
            this.$emit("selected", [...this.selected])
          }
        }
        this.search = ""
        this.$emit("blur")
      }
    },
    onNodeSelect(selected) {
      if (this.clearing) {
        this.clearing = false
        return
      }

      if (this.$refs["treeview_search"]) {
        this.$refs["treeview_search"].focus()
      }

      if (this.pMultiple) {
        const sortedSelectedValues = getItemsInOriginalOrder(this.selected, [...selected])
        if (!compareArray(this.selected, sortedSelectedValues)) {
          this.selected = sortedSelectedValues
        }
      }

      if (this.pEmitOnEverySelection) {
        if (!compareArray(this.selected, this.pSelected)) {
          this.$emit("selected", [...this.selected])
        }
      }
    },
    onNodeActive(selected) {
      if (!this.pMultiple) {
        if (!compareArray(this.selected, selected)) {
          this.selected = [...selected]
        }
        if (selected.length) {
          this.isMenuOpen = false
          this.onMenuOpen(this.isMenuOpen)
        }
      }
    },
    async onNodeOpen() {
      this.isMenuOpen = false
      await this.$nextTick()

      this.isMenuOpen = true
      await this.$nextTick()

      this.$refs["treeview_search"].focus()
    },
    getItemByKey(items, key) {
      const item = items.find(item => item[this.pItemKey] === key)
      if (item) {
        return item
      } else {
        for (const item of items) {
          const childItem = this.getItemByKey(item.children, key)
          if (childItem) {
            return childItem
          }
        }
      }
    }
  },
  watch: {
    pSelected: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !this.selected.length) {
          this.selected = [...newValue]
          this.$emit("validate", newValue)
        }
      }
    },
    search: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue.length) {
          this.isMenuOpen = false
          await this.$nextTick()

          this.isMenuOpen = true
          await this.$nextTick()
        }
      }
    }
  }
}
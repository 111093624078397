/**
 * @file It contains scripts for Report Message component
 */
import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "ReportMessage",
  components: {
    ThemisDecision
  },
  data: () => ({
    originalAudioMessageItem         : undefined,
    originalTextMessageItem          : undefined,
    machineTranslatedTextMessageItem : undefined,
    humanTranslatedTextMessageItem   : undefined,
    attachments                      : new Array(),
    showSideBySide                   : false,
    editDraftMessage                 : false,
    localDraftTextMessageValue       : "",
    draftTextMessageLastSavedTime    : undefined,
    draftMessageAutoSaveInterval     : undefined,
    sendMessageDecision              : undefined,
    sendMessageDecisionNote          : undefined,
    humanTranslateManually           : undefined,
    showRequestHumanTranslationDialog: false,
    showRedoHumanTranslationDialog   : false,
    showDiscardDraftMessageDialog    : false,
    showSendMessageToReporterDialog  : false,
    translationRedoReason            : "",
    citationFound                    : false,
    messageSegments                  : [],
    citations                        : []
  }),
  props: {
    pMessage                            : Object,
    pReportLanguage                     : Object,
    pReportSource                       : String,
    pUsers                              : Array,
    pChannel                            : Object,
    pLanguages                          : Array,
    pIsMessageAdded                     : Boolean,
    pIsRemovingMessage                  : Boolean,
    pIsUpdatingMessageItemValue         : Boolean,
    pIsSendingMessage                   : Boolean,
    pIsMessageSent                      : Boolean,
    pIsReportOpen                       : Boolean,
    pIsUpdatingTranslationStatus        : Boolean,
    pIsTranslationStatusUpdated         : Boolean,
    pIsAddingTranslation                : Boolean,
    pIsTranslationAdded                 : Boolean,
    pShowMessagesInClientDefaultLanguage: Boolean,
    pIsReportNotInClientDefaultLanguage : Boolean,
    pMessageUpdatePolicy                : Object,
    pMessageRemovePolicy                : Object,
    pTranslationUpdatePolicy            : Object,
    pMessageItemAddTranslationsPolicies : Array,
    pIsReportAnonymised                 : Boolean,
    pReplyTemplates                     : Array,
    pIsReplyTemplatesEnabled            : Boolean,
    pHighlightText                      : String,
    pShowTranslations                   : Boolean
  },
  beforeDestroy() {
    if (this.editDraftMessage && this.draftMessageAutoSaveInterval) {
      clearInterval(this.draftMessageAutoSaveInterval)
    }
  },
  computed: {
    sender() {
      return this.pUsers.find(user =>
        user.id === this.pMessage.userId
      )
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.pLanguages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    isSeenByReporter() {
      return this.sender && this.pMessage.status === this.$CONSTANTS.MESSAGE_STATUS.SEEN
    },
    isDraftMessage() {
      return this.pMessage?.status === this.$CONSTANTS.MESSAGE_STATUS.DRAFT
    },
    messageItemToBeDisplayed() {
      if (this.sender) {
        if (this.pShowMessagesInClientDefaultLanguage) {
          return this.originalTextMessageItem
        } else {
          if (this.humanTranslatedTextMessageItem) {
            return this.humanTranslatedTextMessageItem
          } else if (this.machineTranslatedTextMessageItem) {
            return this.machineTranslatedTextMessageItem
          } else {
            return this.originalTextMessageItem
          }
        }
      } else {
        if (this.pShowMessagesInClientDefaultLanguage) {
          if (this.humanTranslatedTextMessageItem) {
            return this.humanTranslatedTextMessageItem
          } else if (this.machineTranslatedTextMessageItem) {
            return this.machineTranslatedTextMessageItem
          } else {
            return this.originalTextMessageItem
          }
        } else {
          return this.originalTextMessageItem
        }

      }
    },
    isTranslated() {
      return this.humanTranslatedTextMessageItem || this.machineTranslatedTextMessageItem
    },
    attachmentNames() {
      let name
      if (this.attachments.length) {
        if (!this.attachments[0].value) {
          name = this.$tc("783", this.attachments.length)
        } else {
          name = this.attachments[0].value.name
          for (let index = 1; index < this.attachments.length; index++) {
            name += `, ${this.attachments[index].value.name}`
          }
        }
      }
      return name
    },
    isTranslationRequiredForReplyMessage() {
      return this.isDraftMessage &&
        (this.originalTextMessageItem.language.id !== this.pReportLanguage.id ||
          this.pReportSource === this.$CONSTANTS.REPORT_SOURCE.PHONE
        )
    },
    isMessageInTranslation() {
      return !!(["open", "redo"].includes(this.pMessage?.translation?.status))
    },
    isTranslationUnintelligible() {
      return !!(this.pMessage?.translation?.resolution === "Unintelligible")
    },
    isTranslationLanguageChangeDeclined() {
      return !!(this.pMessage?.translation?.resolution === "Rejected exception approval")
    },
    isTranslationLanguageChangeApproved() {
      return !!(this.pMessage?.translation?.resolution === "Done" && this.originalTextMessageItem.languageId !== this.pMessage?.translation.sourceLanguageId)
    },
    areTranslationLanguagesSame() {
      return !!(this.pMessage?.translation?.resolution === "Target language equals source language")
    },
    canRequestHumanTranslationForReporterMessage() {
      const originalMessageItem             = this.originalAudioMessageItem || this.originalTextMessageItem
      const messageItemAddTranslationPolicy =
        this.pMessageItemAddTranslationsPolicies.find(messageItemAddTranslationPolicy =>
          messageItemAddTranslationPolicy.id === originalMessageItem.id &&
          (messageItemAddTranslationPolicy?.set?.add && !messageItemAddTranslationPolicy.set.add.length)
        )
      if (messageItemAddTranslationPolicy) {
        return !this.sender && !this.pMessage.translation && this.pIsReportNotInClientDefaultLanguage &&
          this.pChannel.effectiveTranslationPreference === this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED
      }
    },
    canRequestRedoOfTranslation() {
      if (!this.sender && this.pMessage.translation?.status === "done" && this.pTranslationUpdatePolicy?.set?.status) {
        if (this.pTranslationUpdatePolicy.set.status.includes("redo")) {
          return this.$moment().diff(this.pMessage.translation.closedAt) / (60 * 60 * 24 * 1000) < 14
        }
      }
    },
    canMachineTranslateAndSend() {
      return this.isTranslationRequiredForReplyMessage &&
        this.pReportSource !== this.$CONSTANTS.REPORT_SOURCE.PHONE &&
        this.languagesMap[this.pReportLanguage.id]?.machineTranslatable &&
        [this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED,
          this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY].
          includes(this.pChannel.effectiveTranslationPreference)
    },
    canSendViaTranslator() {
      return this.isTranslationRequiredForReplyMessage &&
        this.languagesMap[this.pReportLanguage.id]?.humanTranslatable &&
        [this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED,
          this.$CONSTANTS.TRANSLATION_PREFERENCES.HUMAN_ONLY,
          this.$CONSTANTS.TRANSLATION_PREFERENCES.HUMAN_ADDED].
          includes(this.pChannel.effectiveTranslationPreference)
    },
    canSendMessage() {
      return this.pMessageUpdatePolicy?.set?.status ? this.pMessageUpdatePolicy.set.status.includes("new") : false
    },
    canEditMessage() {
      // for now using send message permission for edit message also. should be replaced with message item update permission.
      return this.canSendMessage
    },
    canRemoveMessage() {
      if (this.pMessageRemovePolicy?.set?.remove) {
        return !this.pMessageRemovePolicy.set.remove.length
      }
      return false
    },
    canShowReplyTemplateForReplyMessage() {
      return this.isDraftMessage &&
        this.originalTextMessageItem.language.id === this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.id
    }
  },
  methods: {
    handleClick(caller) {
      this.humanTranslateManually = false
      switch (caller) {
        case "button_send": {
          if (!this.isTranslationRequiredForReplyMessage) {
            this.sendMessageDecision     = "326"
            this.sendMessageDecisionNote = "327"
            break
          } else {
            if (this.canSendViaTranslator) {
              this.sendMessageDecision     = "330"
              this.sendMessageDecisionNote = "331"
            } else {
              this.sendMessageDecision     = "328"
              this.sendMessageDecisionNote = "329"
            }
            break
          }
        }
        case "button_machine_translate_and_send": {
          this.sendMessageDecision     = "328"
          this.sendMessageDecisionNote = "329"
          break
        }
        case "button_send_via_translator": {
          this.sendMessageDecision     = "330"
          this.sendMessageDecisionNote = "331"
          this.humanTranslateManually  = true
          break
        }
      }
      this.showSendMessageToReporterDialog = true
    },
    sendMessage() {
      clearInterval(this.draftMessageAutoSaveInterval)
      this.$emit("sendMessage", this.pMessage, {
        id                    : this.originalTextMessageItem.id,
        value                 : this.localDraftTextMessageValue,
        humanTranslateManually: this.humanTranslateManually
      })
    },
    useReplyTemplate(item) {
      this.localDraftTextMessageValue = item.content
    },
    getClassForSegment(segment) {
      for (const citation of this.citations) {
        if (citation.trim()) {
          if (segment.trim().toLowerCase().includes(citation.trim().toLowerCase())) {
            return "purple lighten-4"
          }
        }
      }
      return ""
    }
  },
  watch: {
    immediate     : true,
    pHighlightText: {
      handler: async function(newValue) {
        if (!this.messageItemToBeDisplayed?.value) return
        this.citationFound = false
        if (newValue) {
          this.citations     = newValue.split(".").map(citation => citation.trim()).filter(citation => citation)
          this.citationFound = this.citations.some(citation => this.messageItemToBeDisplayed.value.toLowerCase()
            .includes(citation.toLowerCase()))
        }

        if (this.citationFound) {
          this.messageSegments       = this.messageItemToBeDisplayed.value.split(".")
            .map(segment => segment.trim()).filter(segment => segment)
          const toBeHighlightedIndex = this.messageSegments.findIndex(segment => {
            return segment.toLowerCase().trim() && segment.toLowerCase()
              .includes(this.citations[0].toLowerCase().trim())
          })

          await this.$nextTick(() => {
            const messageItemRefs = this.$refs[`${this.messageItemToBeDisplayed.id}_segment_text_message_${toBeHighlightedIndex}`]
            if(messageItemRefs && messageItemRefs.length) {
              messageItemRefs[0].scrollIntoView({
                behavior: "smooth",
                block   : "center"
              })
            }
          })
        }
      }
    },
    pIsMessageAdded: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && this.isDraftMessage) {
          this.editDraftMessage = newValue
        }
      }
    },
    pMessage: {
      immediate: true,
      deep     : true,
      handler  : function(newValue) {
        this.attachments = new Array()
        if (newValue.items) {
          const humanTranslatedItems = new Array()
          for (const messageItem of newValue.items) {
            if (messageItem.type === "audio") {
              this.originalAudioMessageItem = messageItem
            } else if (messageItem.type === "text") {
              if (messageItem.version === "original") {
                this.originalTextMessageItem = messageItem
              } else if (messageItem.version === "machine") {
                this.machineTranslatedTextMessageItem = messageItem
              } else {
                humanTranslatedItems.push(messageItem)
              }
            } else {
              this.attachments.push(messageItem)
            }
          }
          if (humanTranslatedItems.length) {
            for (const humanTranslatedItem of humanTranslatedItems) {
              if (humanTranslatedItem.processedFromId === this.originalAudioMessageItem?.id) {
                this.originalTextMessageItem = humanTranslatedItem
              } else {
                this.humanTranslatedTextMessageItem = humanTranslatedItem
              }
            }
          }
        }
      }
    },
    editDraftMessage: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.localDraftTextMessageValue   = this.originalTextMessageItem.value
          this.draftMessageAutoSaveInterval = setInterval(() => {
            if (this.localDraftTextMessageValue !== this.originalTextMessageItem.value) {
              this.draftTextMessageLastSavedTime = new Date()
              this.$emit("saveMessageItem", {
                id   : this.originalTextMessageItem.id,
                value: this.localDraftTextMessageValue
              })
            }
          }, 5000)
        } else if (this.draftMessageAutoSaveInterval) {
          clearInterval(this.draftMessageAutoSaveInterval)
        }
        this.$emit("editingMessage", newValue)
      }
    },
    pIsSendingMessage: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.SEND_MESSAGE_TO_REPORTER.pActions[1].buttonProps.loading = newValue
        if (!newValue && this.pIsMessageSent) {
          this.editDraftMessage                = false
          this.showSendMessageToReporterDialog = false
        }
      }
    },
    pIsRemovingMessage: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.DISCARD_DRAFT_MESSAGE.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.DISCARD_DRAFT_MESSAGE.pActions[0].buttonProps.disabled = newValue
      }
    },
    pIsTranslationStatusUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showRedoHumanTranslationDialog = false
        }
      }
    },
    pIsUpdatingTranslationStatus: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REDO_HUMAN_TRANSLATION.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.REDO_HUMAN_TRANSLATION.pActions[0].buttonProps.disabled = newValue
      }
    },
    pIsTranslationAdded: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showRequestHumanTranslationDialog = false
        }
      }
    },
    pIsAddingTranslation: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REQUEST_HUMAN_TRANSLATION.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.REQUEST_HUMAN_TRANSLATION.pActions[0].buttonProps.disabled = newValue
      }
    },
    translationRedoReason: {
      immediate: true,
      handler  : function(newValue) {
        this.$DECISIONS.REDO_HUMAN_TRANSLATION.pActions[1].buttonProps.disabled = !newValue
      }
    }
  }
}

/**
 * @file It contains scripts for Logs Component.
 */
import { FILE_TYPE } from "@/constants"
export default {
  name : "Logs",
  props: {
    pLogs               : Array,
    pUsersMap           : Object,
    pGroupsMap          : Object,
    pCountriesMap       : Object,
    pLanguagesMap       : Object,
    pIssueSourcesMap    : Object,
    pIssueStatusesMap   : Object,
    pIssueResolutionsMap: Object,
    pDomainsMap         : Object,
    pIsPrintTemplate    : Boolean
  },
  computed: {
    logs() {
      const logs = new Array()
      for (const pLog of this.pLogs) {
        const user                      = this.pUsersMap[pLog.userId]
        const log                       = {
          id                   : pLog.id,
          user,
          disabledButNotDeleted: user?.disabledButNotDeleted,
          createdAt            : pLog.createdAt
        }
        const constructValuesLogMessage = (log, mapObject, oldValueKey, newValueKey) => {
          return this.$t(this.$LOCALES.LOGS[log.event], {
            [oldValueKey]: mapObject[log.data.initialValue] ? mapObject[log.data.initialValue].name : this.$t("372"),
            [newValueKey]: mapObject[log.data.finalValue] ? mapObject[log.data.finalValue].name : this.$t("372")
          })
        }
        switch(pLog.event) {
          case "ISSUE_ACKNOWLEDGED_AT_CHANGE":
          case "ISSUE_RECEIVED_AT_CHANGE": {
            log.message = this.$t(this.$LOCALES.LOGS[pLog.event], {
              old: pLog.data.initialValue ? this.$moment(pLog.data.initialValue).format("D MMMM YYYY HH:mm (UTCZ)") : this.$t("372"),
              new: pLog.data.finalValue ? this.$moment(pLog.data.finalValue).format("D MMMM YYYY HH:mm (UTCZ)") : this.$t("372")
            })
            break
          }
          case "ISSUE_ASSIGNEE_CHANGE": {
            log.message = constructValuesLogMessage(
              pLog,
              this.pUsersMap,
              "oldAssignee",
              "newAssignee"
            )
            break
          }
          case "ISSUE_STATUS_CHANGE": {
            log.message = constructValuesLogMessage(
              pLog,
              this.pIssueStatusesMap,
              "oldStatus",
              "newStatus"
            )
            break
          }
          case "ISSUE_DOMAIN_CHANGE": {
            log.message = constructValuesLogMessage(
              pLog,
              this.pDomainsMap,
              "oldDomain",
              "newDomain"
            )
            break
          }
          case "ISSUE_LABELS_CHANGE": {
            log.message = this.$t(this.$LOCALES.LOGS[pLog.event], {
              old: pLog.data.initialValue?.length ? pLog.data.initialValue : this.$t("372"),
              new: pLog.data.finalValue?.length ? pLog.data.finalValue : this.$t("372")
            })
            break
          }
          case "ISSUE_RESOLUTION_CHANGE": {
            if (!pLog.data.finalValue) {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event].CLEAR)
            } else {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event].SET, {
                resolution: this.pIssueResolutionsMap[pLog.data.finalValue] ? this.pIssueResolutionsMap[pLog.data.finalValue].name : this.$t("372")
              })
            }
            break
          }
          case "ISSUE_INVITE_USER":
          case "ISSUE_REMOVE_USER":
          case "ISSUE_INVITE_GROUP":
          case "ISSUE_REMOVE_GROUP": {
            let name
            if (pLog.data.otherInformation.userId) {
              name = this.pUsersMap[pLog.data.otherInformation.userId].name
            } else {
              name = pLog.data.otherInformation.groupName
            }
            log.message = this.$t(
              this.$LOCALES.LOGS[pLog.event], {
                name,
                role: pLog.data.otherInformation.roleName,
                toId: pLog.data.otherInformation.toId
              }
            )
            break
          }
          case "MESSAGE_SENT": {
            if (pLog.userId) {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event].CLIENT)
            } else {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event].REPORTER)
            }
            break
          }
          case "MESSAGE_SEEN": {
            if (!pLog.userId) {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event])
            }
            break
          }
          case "ISSUE_DATA_RETENTION_SCHEDULED": {
            log.message = this.$t(this.$LOCALES.LOGS[pLog.event], {
              timeFrame: this.$moment(pLog.data.otherInformation.dataRetainedUntil).format("D MMMM YYYY")
            })
            break
          }
          case "ISSUE_DATA_RETAINED_UNTIL_CHANGE": {
            if (!pLog.data.finalValue) {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event])
            }
            break
          }
          case "DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_TRIGGER": {
            let logMessage = ""
            if (pLog.data.otherInformation.automationName) {
              logMessage = this.$t("1385", {
                automationName: pLog.data.otherInformation.automationName
              })
            } else {
              logMessage = this.$t("1123", {
                domain: this.pDomainsMap[pLog.data.otherInformation.domainId].name
              })
            }

            log.message = this.$t(this.$LOCALES.LOGS[pLog.event], { logMessage })
            break
          }
          case "ISSUE_DUE_DATE_CHANGE": {
            log.message = this.$t(this.$LOCALES.LOGS[pLog.event], {
              old: pLog.data.initialValue ? this.$moment(pLog.data.initialValue).format("D MMMM YYYY HH:mm (UTCZ)") : this.$t("1856"),
              new: pLog.data.finalValue ? this.$moment(pLog.data.finalValue).format("D MMMM YYYY HH:mm (UTCZ)") : this.$t("1856")
            })
            break
          }
          case "ISSUE_DOCUMENT_UPDATE":
          case "MESSAGE_ITEM_UPDATE": {
            if ("folderId" in pLog.data.otherInformation) {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event].UPDATE_FOLDER, {
                type      : FILE_TYPE.DOCUMENT,
                folderName: pLog.data.otherInformation.folderName ?? `Issue ${pLog.entityId}`,
                name      : pLog.data.otherInformation.name
              })
            } else if (pLog.data.otherInformation.removed) {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event].REMOVE, {
                fileName: pLog.data.otherInformation.name
              })
            } else if (pLog.data.otherInformation.added) {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event].ADD, {
                fileName: pLog.data.otherInformation.name
              })
            } else if (pLog.data.otherInformation.updated) {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event].EDIT, {
                fileName: pLog.data.otherInformation.name
              })
            }
            break
          }
          case "ISSUE_DOCUMENT_CREATE": {
            if (pLog.data.otherInformation?.folderId !== null) {
              log.message = this.$t("1949", {
                documentName: pLog.data.otherInformation.name,
                folderName  : pLog.data.otherInformation.folderName
              })
            } else {
              log.message = this.$t(this.$LOCALES.LOGS[pLog.event], {
                name: pLog.data.otherInformation.name
              })
            }
            break
          }
          case "FOLDER_DELETE":
          case "FOLDER_CREATE": {
            log.message = this.$t(this.$LOCALES.LOGS[pLog.event], {
              folderName  : pLog.data.otherInformation.name,
              parentFolder: pLog.data.otherInformation.parentFolderName ?? `Issue ${pLog.entityId}`
            })
            break
          }
          case "FOLDER_MOVE": {
            log.message = this.$t(this.$LOCALES.LOGS[pLog.event], {
              type      : FILE_TYPE.FOLDER,
              folderName: pLog.data.otherInformation.parentFolderName ?? `Issue ${pLog.entityId}`,
              name      : pLog.data.otherInformation.name
            })
            break
          }
          default: {
            log.message = this.$t(this.$LOCALES.LOGS[pLog.event], pLog.data.otherInformation)
          }
        }
        if (log.message) {
          logs.push(log)
        }
      }
      return logs
    },

    getClassForUserName(){
      return this.pIsPrintTemplate ? "text-caption" : "text-caption secondary--text text--darken-2"
    }
  }
}

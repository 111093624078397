import { render, staticRenderFns } from "./template.pug?vue&type=template&id=bbfa22e6&scoped=true&lang=pug&external"
import script from "./script.js?vue&type=script&lang=js&external"
export * from "./script.js?vue&type=script&lang=js&external"
import style0 from "./style.sass?vue&type=style&index=0&id=bbfa22e6&prod&lang=sass&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbfa22e6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCard,VDivider,VListItem,VListItemContent,VListItemTitle,VMenu,VTextField,VTreeview})

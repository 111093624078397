import ThemisReadOnly from "@/components/shared/read-only"
import { ARRAY, FIELD_TYPES } from "@/constants"

export default {
  name      : "ReportIntakeForms",
  components: {
    ThemisReadOnly
  },
  data: () => ({
    showTextFieldsInClientDefaultLanguage: true
  }),
  props: {
    pFormInstance: Object
  },
  computed: {
    hasTranslationForShortText() {
      const shortTextFormInstanceFields = this.pFormInstance.formInstanceFields
        .filter(field => field.type === FIELD_TYPES.SHORT_TEXT.value)

      const hasTranslation = shortTextFormInstanceFields.some(field =>
        field.formInstanceFieldValues.some(value => value.translatedValue !== null)
      )
      return hasTranslation
    }
  },
  methods: {
    isValueNull(values) {
      if (values.length === 1) {
        if (values[ARRAY.FIRST] === null) {
          return true
        }
      }
    },
    getFormInstanceFieldValueForDisplay(formInstanceField) {
      const formInstanceFieldValues = formInstanceField.formInstanceFieldValues

      const { value, translatedValue } = formInstanceFieldValues[ARRAY.FIRST]

      if (formInstanceField.type === FIELD_TYPES.SHORT_TEXT.value) {
        if (translatedValue && this.showTextFieldsInClientDefaultLanguage) {
          return {
            icon   : "mdi-robot",
            tooltip: this.$t("347"),
            value  : translatedValue
          }
        }

        if (value === null) {
          return {
            value: this.$t("1418")
          }
        }
      }

      if (formInstanceField.type === FIELD_TYPES.BOOLEAN.value) {
        return {
          value: value === "true" ? this.$t("1574") :
            value === "false" ? this.$t("1575") : this.$t("1418")
        }
      }

      if (value === null) {
        return {
          value: this.$t("1018")
        }
      }

      if (formInstanceField.type === FIELD_TYPES.MULTIPLE_OPTION_LIST.value) {
        return formInstanceFieldValues.map(
          formInstanceFieldValue => formInstanceFieldValue.value).toString()
      }
      return {
        value
      }
    }
  },
  watch: {
  }
}
export default {
  name : "ReadOnlyFields",
  data : () => ({}),
  props: {
    pLabel  : String,
    pValue  : String,
    pIcon   : String,
    pTooltip: String
  }
}
